.navbar-light .navbar-nav .nav-link {
	display: block;
	padding: 32px 16px;
	font-size: 15px;
	font-weight: 600;
	color: #081624;
	position: relative;
}

.navbar-light .navbar-nav .nav-link:hover {
	color: #081624 !important;
}

.card {
	background: none;
	border: none;
}

.card-body {
	background: none;
}

.card-header {
	background: none;
	border: none;
}

.card-header button {
	background: none;
	border: none;
	outline: none !important;
	text-decoration: none !important;
	color: #081624 !important;
}

.card-header button:focus {
	box-shadow: none;
}
