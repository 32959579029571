body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oswald", sans-serif;
}

.navbar-expand-md .navbar-nav a.active::after {
  width: 100%;
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  #basic-navbar-nav {
    background: #081624;
    text-align: left;
    padding: 8px 5px;
    margin-bottom: 15px;
  }
  .navbar-light .navbar-nav .nav-link {
    padding: 5px 10px !important;
    margin: 2px 5px;
    text-decoration: none;
    color: #fff !important;
    background-color: transparent;
    transition: all 0.2s;
  }
  .navbar-light .navbar-nav .nav-link:hover {
    background: #25394c;
    border-radius: 6px;
    color: #fff !important;
    text-decoration: none;
    outline: none;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  #basic-navbar-nav {
    background: #081624;
    text-align: left;
    padding: 8px 5px;
    margin-bottom: 15px;
  }
  .navbar-light .navbar-nav .nav-link {
    padding: 5px 10px !important;
    margin: 2px 5px;
    text-decoration: none;
    color: #fff !important;
    background-color: transparent;
    transition: all 0.2s;
  }
  .navbar-light .navbar-nav .nav-link:hover {
    background: #25394c;
    border-radius: 6px;
    color: #fff !important;
    text-decoration: none;
    outline: none;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  #basic-navbar-nav {
    flex-direction: column-reverse;
  }
  .navbar {
    flex-direction: column !important;
  }
  .navbar-nav {
    margin: auto !important;
  }
  .navbar-brand {
    margin-right: 0;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .navbar-nav .dropdown-menu {
    padding: 0;
  }
  .navbar-nav .dropdown-menu .dropdown-container {
    background-image: url("./assets/products-menu-bg-1.webp");
    background-repeat: no-repeat;
    background-position: bottom right;
    width: 700px;
    box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    clip: rect(0, 5000px, 5000px, -5000px);
    background-color: #fafafa;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .navbar-nav .dropdown-menu .dropdown-container a:hover {
    background: none;
  }
  .navbar-nav .dropdown-menu .dropdown-container a {
    color: #888;
    font-size: 14px;
  }

  .navbar-expand-md
    .navbar-nav
    .dropdown-menu
    .outer-custom-dropdown
    .dropdown-title {
    color: #656565;
    font-weight: bold;
    padding-bottom: 10px;
    position: relative;
    border-bottom: 1px solid #ccc;
    margin-left: 1.5rem;
    padding-left: 0;
  }
}

/* .productImage {
  height: 500px;
}

.productImage img {
  max-width: 100%;
  max-height: 100%;
} */
